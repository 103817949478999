import { formatDateDayMonthYear, formatDateMonthYear } from "@/helpers/date";
import {
  Buyer,
  CustomerLotStatus,
  DetailsLot,
  EoiDetails,
  EstateContacts,
  Lot,
  ResponseEoiDetails,
  ResponseEstateContacts,
  ResponseLot,
  ResponseLotForList,
  ContractConditions
} from "@/types/CustomerLotDetails";
import { numFormatter } from "@/helpers/transformation/number";
import { getLotStatus } from "@/helpers/customer";
import {
  CustomerWaitlistDetails,
  CustomerWaitlistDetailsBeforeUpdate,
  CustomerWaitlistDetailsForUpdate,
  CustomerWaitlistDetailsResponse,
} from "@/types/CustomerWaitlistDetails";
import { EoiStatus } from "@/enums/EoiStatus";
import { EoiContractConditions } from "@/enums/EoiContractConditions";

export function normalizeLotDetails(data: ResponseLot): Lot {
  const {
    size,
    stageId,
    blockDepth,
    blockWidth,
    releaseDate,
    price,
    status,
    title,
    documents,
    contracts,
    financeDueDate,
    settlementDate,
    estateId,
    _id,
    landownerId,
  } = data;

  return {
    title,
    status: status as CustomerLotStatus,
    size: `${size.toLocaleString()} m²`,
    stage: stageId.title,
    dimensions:
      blockWidth && blockDepth
        ? `${blockWidth}m x ${blockDepth}m`
        : "Irregular",
    releaseDate: releaseDate ? formatDateDayMonthYear(releaseDate) : "-",
    price: `$${price.toLocaleString()}`,
    registrationDate: formatDateMonthYear(stageId.registrationDate),
    documents,
    contracts,
    financeDueDate: financeDueDate
      ? formatDateDayMonthYear(financeDueDate)
      : "-",
    settlementDate: settlementDate
      ? formatDateDayMonthYear(settlementDate)
      : "-",
    estate: {
      title: estateId.title,
      id: estateId._id,
      isRefundable: estateId.isRefundable,
    },
    id: _id,
    landowner: {
      id: landownerId._id,
    },
  };
}

export function normalizeEoiDetails(data: ResponseEoiDetails): EoiDetails {
  const {
    buyersData: { firstBuyer, secondBuyer },
    buyerType,
    conveyancerDetails,
    contractConditions,
    status,
    _id,
    createdAt,
    updatedAt,
    moneyHoldId,
    contractDocuments,
    refundData,
    agentReferrer,
  } = data;

  const eoiData = {
    buyers: ([firstBuyer, secondBuyer] as Buyer[]).filter((item) => item),
    buyerType,
    conveyancer: conveyancerDetails,
    contractConditions: {
      subjectToFinance: contractConditions?.subjectToFinance ? "Yes" : "No",
      financeStatus: contractConditions?.financeStatus || null,
      FIRB: contractConditions?.FIRB ? "Yes" : "No",
      otherConditions: contractConditions?.otherConditions || "No",
      financeBrokerDetails: contractConditions?.financeBrokerDetails
    },
    status,
    id: _id,
    createdAt,
    updatedAt,
    moneyHoldId,
    contractDocuments,
    refundData,
    agentReferrer,
  } as EoiDetails;

  return updateContractConditions(eoiData, contractConditions);
};
function updateContractConditions(eoiDetailsObj: EoiDetails, contractConditions: ContractConditions = {}) {
  if (isContractConditionsNotExist(eoiDetailsObj, contractConditions)) {
    delete eoiDetailsObj.contractConditions;
  } else {
    removeContractConditionsFields(eoiDetailsObj, contractConditions);
  }
  return eoiDetailsObj;
}

function isContractConditionsNotExist(eoiDetailsObj: EoiDetails, contractConditions: ContractConditions) {
  return !contractConditions || Object.keys(contractConditions).length === 0;
}

function removeContractConditionsFields(eoiDetailsObj: EoiDetails, contractConditions: ContractConditions) {
  if (!(EoiContractConditions.FIRB in contractConditions) && eoiDetailsObj.contractConditions) {
    delete eoiDetailsObj.contractConditions.FIRB;
  }
  if (!(EoiContractConditions.FINANCE_STATUS in contractConditions)
    && eoiDetailsObj.contractConditions) {
    delete eoiDetailsObj.contractConditions.financeStatus;
  }
  if (!(EoiContractConditions.OTHER_CONDITIONS in contractConditions)
    && eoiDetailsObj.contractConditions) {
    delete eoiDetailsObj.contractConditions.otherConditions;
  }
  if (!(EoiContractConditions.SUBJECT_TO_FINANCE in contractConditions)
    && eoiDetailsObj.contractConditions) {
    delete eoiDetailsObj.contractConditions.subjectToFinance;
  }
}

export function normalizeEstateContacts(
  data: ResponseEstateContacts
): EstateContacts {
  const {
    title,
    address,
    landownerId: { detailsId: { companyName }, email, firstName, lastName, phone },
  } = data;
  return {
    developer: companyName,
    estate: title,
    address: address || "-",
    person: `${firstName} ${lastName}` || "-",
    phone: phone || "-",
    email: email || "-",
  };
}

export function normalizeLots(data: ResponseLotForList[]): DetailsLot[] {
  const lotsWithoutTempEoiStatus = data.filter(
    (item: ResponseLotForList) => item.eoi.status !== EoiStatus.TEMP
  );
  return lotsWithoutTempEoiStatus.map((item: ResponseLotForList) => {
    const {
      lot: { id, size, price, title },
      eoi,
      estate,
    } = item;
    return {
      status: getLotStatus(eoi.status, item.lot.status),
      size: `${size.toLocaleString()} m2`,
      price: `$${numFormatter(price)}`,
      title,
      id,
      eoiId: eoi.id,
      estate: {
        title: estate.title,
        logoUrl: estate.logoUrl,
        id: estate.id,
      },
    };
  });
}

type WaitlistDetailsMode = "edit" | "";
export function normalizeWaitlistDetails(
  data: CustomerWaitlistDetailsResponse,
  mode?: WaitlistDetailsMode
): CustomerWaitlistDetails {
  const {
    customerId: {
      firstName,
      lastName,
      phone,
      email,
      state,
      postcode,
    },
    lotSize,
    frontage,
    budget,
    estateId: { logoUrl, title },
  } = data;
  const isEdit = mode === "edit";
  return {
    buyer: {
      firstName,
      lastName,
      email,
      phone,
      state,
      postcode
    },
    lot: {
      size: isEdit
        ? [lotSize.from, lotSize.to]
        : {
            from: `${Number(lotSize.from).toLocaleString()} m2`,
            to: `${Number(lotSize.to).toLocaleString()} m2`,
          },
      frontage: isEdit
        ? [frontage.from, frontage.to]
        : {
            from: `${Number(frontage.from).toLocaleString()} m`,
            to: `${Number(frontage.to).toLocaleString()} m`,
          },
      budget: isEdit
        ? [budget.from, budget.to]
        : {
            from: `${Number(budget.from).toLocaleString()} $`,
            to: `${Number(budget.to).toLocaleString()} $`,
          },
    },
    estate: {
      logo: logoUrl,
      title,
      id: data.estateId._id,
    },
  };
}

export function normalizeDataBeforeUpdate(
  data: CustomerWaitlistDetailsBeforeUpdate
): CustomerWaitlistDetailsForUpdate {
  const {
    lot: { budget, frontage, size, stage },
  } = data;
  return {
    budget: {
      from: Number(budget[0]),
      to: Number(budget[1]),
    },
    lotSize: {
      from: Number(size[0]),
      to: Number(size[1]),
    },
    frontage: {
      from: Number(frontage[0]),
      to: Number(frontage[1]),
    },
  };
}
