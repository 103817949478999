export function numFormatter(num: number): string {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else return String(num);
}

export const parseStringToNumber = (value: string | number): number => {
  if (!value) return 0;
  if (typeof value !== "string") return value;
  return Number(value.replace(/\D/g, ""));
};
